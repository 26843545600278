import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.scss'

export const OurClients = (props) => {
  return (
    <div id='clients' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Clients</h2>
          <p>We work hard to deliver the best results for our clients.</p>
        </div>
        <Swiper
          spaceBetween={20}
          style={{ height: '100px' }}
          slidesPerView={2}
          autoplay={'delay=3000'}
          b
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 4,
            },
          }}
        >
          {props.data
            ? props.data.map((d, i) => (
                <SwiperSlide>
                  <div className='image-container'>
                    <img
                      src={d.img}
                      alt='...'
                      className='clients-img grayscale'
                    />
                  </div>
                </SwiperSlide>
              ))
            : 'loading'}
        </Swiper>
      </div>
    </div>
  )
}
